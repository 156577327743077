import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import RequiredAdminRules from "./controller/RequiredAdminRules";
import { Subscription } from "./pages/subscription/Subscription";
import { PlanSubscription } from "./pages/subscription/PlanSubscription";
import LoginAdmin from "./Auth/Admin/LoginAdmin";
import PersistLogin from "./hooks/PersistLogin";
import CheckAuth from "./controller/CheckAuth";
import { ToastContainer } from "react-toastify";
import icon from "./assets/iconTitle.jpeg";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Admin - gestion Center</title>
        <link rel="icon" href={icon} />
      </Helmet>
      <Routes>
        <Route path="/" element={<LoginAdmin />} />
        <Route element={<PersistLogin />}>
          <Route element={<CheckAuth />}>
            {/* admin */}
            <Route element={<RequiredAdminRules allowedRules={["Admin"]} />}>
              <Route element={<Dashboard />}>
                <Route
                  index
                  path="/dashboard/subscription"
                  element={<Subscription />}
                />
                <Route path="/dashboard/Plans" element={<PlanSubscription />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </div>
  );
}

export default App;
