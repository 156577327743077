import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
  headers:{"Content-Type":"application/json"},
  withCredentials:true
});



const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});




export default axiosPrivate;



