import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const CheckAuth = () => {
  const {accessToken } = useSelector((e) => e.auth);
  return accessToken ? <Outlet /> : <Navigate to="/" />;
};

export default CheckAuth;
