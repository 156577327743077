import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchSubscriptions = createAsyncThunk(
  "subscriptions/fetchSubscriptions",
  async (centerId) => {
    const response = await axiosPrivate.get(`/subscriptions/${centerId}`);
    return response.data;
  }
);

export const addSubscription = createAsyncThunk(
  "subscriptions/addSubscription",
  async ({ centerId, planId }) => {
    const subscriptionData = {
      center_id: centerId,
      plan_id: planId,
    };

    const response = await axiosPrivate.post(
      "/subscriptions",
      subscriptionData
    );
    return response.data;
  }
);

export const deleteSubscription = createAsyncThunk(
  "subscriptions/deleteSubscription",
  async (subscriptionId) => {
    await axiosPrivate.delete(`/subscriptions/${subscriptionId}`);
    return subscriptionId;
  }
);

export const updateSubscription = createAsyncThunk(
  "subscriptions/updateSubscription",
  async ({ subscriptionId, updatedData }) => {
    const response = await axiosPrivate.put(
      `/subscriptions/${subscriptionId}`,
      updatedData
    );
    return response.data;
  }
);

export const fetchCenters = createAsyncThunk(
  "centers/fetchCenters",
  async ({ page = 1, pageSize = 10, searchTerm = "" }) => {
    const response = await axiosPrivate.get("/centers", {
      params: { page, pageSize, searchTerm },
    });
    return response.data;
  }
);

// Give Access (Update active status of a center)
export const giveAccess = createAsyncThunk(
  "subscriptions/giveAccess",
  async ({ centerId, active }) => {
    await axiosPrivate.post(`/centers/${centerId}/access`, { active });
    return { centerId, active };
  }
);

export const deleteCenter = createAsyncThunk(
  "centers/deleteCenter",
  async (centerId) => {
    await axiosPrivate.delete(`/centers/${centerId}`);
    return centerId; // Returning the ID of the deleted center
  }
);

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptions: [],
    centers: [],
    status: "idle",
    error: null,
    currentPage: 1,
    pageSize: 10,
    totalCenters: 0,
    totalPages: 0,
  },
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscriptions = action.payload;
      })
      .addCase(fetchSubscriptions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addSubscription.fulfilled, (state, action) => {
        state.subscriptions.push(action.payload);
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        const id = action.payload;
        state.subscriptions = state.subscriptions.filter(
          (subscription) => subscription.id !== id
        );
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        const index = state.subscriptions.findIndex(
          (subscription) => subscription.id === action.payload.id
        );
        if (index !== -1) {
          state.subscriptions[index] = action.payload;
        }
      })
      .addCase(fetchCenters.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCenters.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.centers = action.payload.centers;
        state.totalCenters = action.payload.totalCenters;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchCenters.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(giveAccess.fulfilled, (state, action) => {
        const { centerId, active } = action.payload;
        const index = state.centers.findIndex(
          (center) => center.id === centerId
        );
        if (index !== -1) {
          state.centers[index].active = active;
        }
      })
      .addCase(giveAccess.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteCenter.fulfilled, (state, action) => {
        const centerId = action.payload;
        state.centers = state.centers.filter(
          (center) => center.id !== centerId
        );
      })
      .addCase(deleteCenter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPage } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
