import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';


export const fetchSubscriptionPlans = createAsyncThunk(
  'subscriptionPlans/fetchAll',
  async () => {
    const response = await axiosPrivate.get('/subscription-plans');
    return response.data;
  }
);

export const addSubscriptionPlan = createAsyncThunk(
  'subscriptionPlans/add',
  async (plan) => {
    const response = await axiosPrivate.post("/subscription-plans", plan);
    return response.data;
  }
);

export const updateSubscriptionPlan = createAsyncThunk(
  'subscriptionPlans/update',
  async ({ id, plan }) => {
    const response = await axiosPrivate.put(`/subscription-plans/${id}`, plan);
    return response.data;
  }
);

export const deleteSubscriptionPlan = createAsyncThunk(
  'subscriptionPlans/delete',
  async (id) => {
    await axiosPrivate.delete(`/subscription-plans/${id}`);
    return id;
  }
);

// Create slice
const subscriptionPlansSlice = createSlice({
  name: 'subscriptionPlans',
  initialState: {
    plans: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.plans = action.payload;
      })
      .addCase(fetchSubscriptionPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addSubscriptionPlan.fulfilled, (state, action) => {
        state.plans.push(action.payload);
      })
      .addCase(updateSubscriptionPlan.fulfilled, (state, action) => {
        const index = state.plans.findIndex(plan => plan.id === action.payload.id);
        if (index !== -1) {
          state.plans[index] = action.payload;
        }
      })
      .addCase(deleteSubscriptionPlan.fulfilled, (state, action) => {
        state.plans = state.plans.filter(plan => plan.id !== action.payload);
      });
  },
});


export default subscriptionPlansSlice.reducer;
