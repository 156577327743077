import { useDispatch } from "react-redux";
import { refresh } from "../redux/Auth/authSlice";

const  useRefreshToken = () => {

  const dispatch = useDispatch()
  const refreshAccessToken = async () => {
    try {
      const {accessToken} = await dispatch(refresh()).unwrap();
      return accessToken
    } catch (error) {
      throw error;
    }
  };

  return refreshAccessToken;
};

export default useRefreshToken;
    