import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { adminLogin } from "../../redux/Auth/authSlice";
import './style.css'

const LoginAdmin = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [localLoading, setLocalLoading] = useState(false); 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.password) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters long";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
        try {
            await dispatch(adminLogin(formData)).unwrap(); 
            setLocalLoading(true);
            setTimeout(() => {
                navigate("/dashboard/subscription");
            }, 1000); 
        } catch (error) {
          const status = error?.status || error?.response?.status;
          switch (status) {
            case 403:
              navigate("/Forbidden");
              break;
              case 404:
                toast.error("L'utilisateur est introuvable ! ");
                break;
            case 400:
              toast.error("mote de passe ou email incorrecte")
              break;
            case 401:
              navigate("/Unauthorized");
              break;
            case 500:
              navigate("/internal-error");
              break;
            case 402:
              toast.error(
                "Abonnement terminé. Veuillez renouveler votre abonnement."
              );
              break;
            default:
              toast.error(error.message);
              break;
          }
        }
    }
};


  return (
    <>
      <div className="modalform">
        <div className="form-wizard">
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <div className="steps-container">
              <div className="step current">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}

                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <span className="error">{errors.password}</span>
                )}
              </div>
            </div>
            {errors.general && <span className="error">{errors.general}</span>}
            <div className="controls">
              <button type="submit" disabled={loading || localLoading}>
                {localLoading ? "Loading..." : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginAdmin;
