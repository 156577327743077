import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./Auth/authSlice";

import subscriptionReducer from './subscription/subscriptionSlice';

import subscriptionPlansReducer  from './subscription/subscriptionPlansSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    subscriptions: subscriptionReducer, 
    subscriptionPlans: subscriptionPlansReducer,
  },
  devTools: process.env.REACT_APP_ENV !== "production",
});

export default store;
