import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Container,
  Modal,
  Button,
  Pagination,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import { FaEye, FaPlus, FaTrash } from "react-icons/fa";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDebounce } from "use-debounce";
import { fetchSubscriptionPlans } from "../../redux/subscription/subscriptionPlansSlice";
import {
  fetchCenters,
  fetchSubscriptions,
  addSubscription,
  deleteSubscription,
  setCurrentPage,
  giveAccess,
  deleteCenter,
} from "../../redux/subscription/subscriptionSlice";

export const Subscription = () => {
  const dispatch = useDispatch();
  const centers = useSelector((state) => state.subscriptions.centers);
  const { currentPage, pageSize, totalCenters } = useSelector(
    (state) => state.subscriptions
  );
  const { plans } = useSelector((state) => state.subscriptionPlans);
  const { subscriptions } = useSelector((state) => state.subscriptions);
  const status = useSelector((state) => state.subscriptions.status);

  // modal de tout subscription
  const [showModal, setShowModal] = useState(false);

  // modal de supprimer supscription
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteCenterModal, setShowDeleteCenterModal] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState(null);

  // modal de affecte plan a center
  const [showAddModal, setShowAddModal] = useState(false);
  const [centerToDelete, setCenterToDelete] = useState(null);
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1400);

  // fetch de plans
  useEffect(() => {
    if (showAddModal) {
      dispatch(fetchSubscriptionPlans());
    }
  }, [dispatch, showAddModal]);

  // fetch tout le center
  useEffect(() => {
    dispatch(
      fetchCenters({
        page: currentPage,
        pageSize,
        searchTerm: debouncedSearchTerm,
      })
    );
  }, [dispatch, currentPage, pageSize, debouncedSearchTerm]);

  console.log("centers", centers);
  //  fetch tout les subscriptions
  const handleFetchSubscriptions = async (centerId) => {
    try {
      await dispatch(fetchSubscriptions(centerId)).unwrap();
      setShowModal(true);
    } catch (err) {
      toast.error("Error fetching subscriptions");
    }
  };

  // Close modal subscription
  const handleClose = () => {
    setShowModal(false);
  };

  // Function to format date using moment
  const formatDate = (dateString) => {
    return moment(dateString)
      .add(-1, "hours")
      .locale("fr")
      .format("DD/MM/YYYY HH:mm");
  };

  // function delete
  const handleDeleteSubscription = (subscriptionId) => {
    setSubscriptionToDelete(subscriptionId);
    setShowDeleteModal(true);
  };

  console.log("subscriptionToDelete",subscriptionToDelete);
  // function confirmation delte
  const confirmDelete = async () => {
    if (subscriptionToDelete) {
      try {
        await dispatch(deleteSubscription(subscriptionToDelete));
        toast.success("Subscription deleted successfully");
      } catch (err) {
        toast.error("Error deleting subscription");
      }
      setShowDeleteModal(false);
      setSubscriptionToDelete(null);
    }
  };

  // function affiche modal ajoute plan
  const handleShowAddModal = (centerId) => {
    setSelectedCenterId(centerId);
    setShowAddModal(true);
  };

  // function close  modal ajoute plan
  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setSelectedPlanId("");
    setSelectedCenterId("");
  };

  // function ajoute sub
  const handleAddSubscription = async () => {
    if (selectedCenterId && selectedPlanId) {
      try {
        await dispatch(
          addSubscription({
            centerId: selectedCenterId,
            planId: selectedPlanId,
          })
        ).unwarp;
        toast.success("Subscription added successfully");
        handleCloseAddModal();
      } catch (err) {
        toast.error("Error adding subscription");
      }
    }
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const totalPages = Math.ceil(totalCenters / pageSize);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    dispatch(setCurrentPage(1));
  };

  // Function to toggle the active status of a center
  const handleToggleActive = async (centerId, active) => {
    try {
      await dispatch(giveAccess({ centerId, active }));
      toast.success(
        `Center ${active ? "activated" : "deactivated"} successfully`
      );
    } catch (err) {
      toast.error("Error updating active status");
    }
  };

  const handleDeleteCenter = (centerId) => {
    setCenterToDelete(centerId);
    setShowDeleteCenterModal(true);
  };

  // Function to confirm center deletion
  const confirmDeleteCenter = async () => {
    if (centerToDelete) {
      try {
        await dispatch(deleteCenter(centerToDelete));
        toast.success("Center deleted successfully");
      } catch (err) {
        toast.error("Error deleting center");
      }
      setShowDeleteCenterModal(false);
      setCenterToDelete(null);
    }
  };

  return (
    <Container>
      <Row className="align-items-center ">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h3>Tableau des Centers</h3>
        </Col>
        <Col xs={12} md={4} className="ms-auto">
          <Form.Control
            type="text"
            placeholder="Rechercher par Name et ref "
            style={{ width: "100%" }}
            size="sm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <div className="d-flex justify-content-between align-items-center mb-3"></div>
      {status === "loading" && <p>Loading centers...</p>}
      {centers?.length > 0 ? (
        <Table striped hover responsive>
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Nom Center</th>
              <th>Nom User</th>
              <th>Ref</th>
              <th>Address</th>
              <th>Email</th>
              <th>Telephone</th>
              <th>Active</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {centers.map((center, index) => (
              <tr key={center.id} className="text-center">
                <td>{index + 1}</td>
                <td>{center.name}</td>
                <td>
                  {center.users && center.users[0]
                    ? `${center.users[0].profile?.prenom} ${center.users[0].profile?.nom}`
                    : ""}
                </td>
                <td>{center.ref}</td>
                <td>{center.address}</td>
                <td>
                  {center.users && center.users[0] ? center.users[0].email : ""}
                </td>
                <td>
                  {center.users && center.users[0].profile
                    ? center.users[0].profile?.phone
                    : ""}
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={center.active}
                    onChange={() =>
                      handleToggleActive(center.id, !center.active)
                    }
                    className="form-check-input"
                  />
                </td>
                <td>
                  <FaEye
                    className="m-2"
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={() => handleFetchSubscriptions(center.id)}
                  />
                  <FaPlus
                    className="m-2"
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => handleShowAddModal(center.id)}
                  />
                  <FaTrash
                    className="m-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleDeleteCenter(center.id)} 
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No centers available.</p>
      )}

      <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>

      {/* Modal for subscriptions */}
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <Modal.Title>Subscriptions for Center:</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {status === "loading" ? (
            <p>Loading subscriptions...</p>
          ) : subscriptions.length > 0 ? (
            <Table bordered hover responsive>
              <thead>
                <tr className="text-center">
                  <th>N</th>
                  <th>Montant</th>
                  <th>pack</th>
                  <th>duree</th>
                  <th>start</th>
                  <th>end</th>
                  <th>actions</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map((subscription, index) => (
                  <tr key={subscription.id} className="text-center">
                    <td>{index + 1}</td>
                    <td>{subscription.amount}</td>
                    <td>{subscription.name}</td>
                    <td>{subscription.duration}</td>
                    <td>{formatDate(subscription.startDate)}</td>
                    <td>{formatDate(subscription.endDate)}</td>
                    <td>
                      <FaTrash
                        className="m-2"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          handleDeleteSubscription(subscription.id)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No subscriptions available for this center.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal for confrime suppression  */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet abonnement ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal ajoute plan a center  */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une nouvelle subcription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="subscriptionSelect" className="form-label">
                Sélectionner un abonnement
              </label>
              <select
                id="subscriptionSelect"
                className="form-select"
                value={selectedPlanId}
                onChange={(e) => setSelectedPlanId(e.target.value)}
              >
                <option value="">Choisissez...</option>
                {plans.map((plan) => (
                  <option key={plan.id} value={plan.id}>
                    {plan.name}
                  </option>
                ))}
              </select>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleAddSubscription}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for confirming center deletion */}
      <Modal show={showDeleteCenterModal} onHide={() => setShowDeleteCenterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la suppression du center</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir supprimer ce center ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteCenterModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDeleteCenter}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
