import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchSubscriptionPlans , deleteSubscriptionPlan , updateSubscriptionPlan , addSubscriptionPlan} from '../../redux/subscription/subscriptionPlansSlice'
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const PlanSubscription = () => {
  const dispatch = useDispatch();
  const { plans, loading } = useSelector((state) => state.subscriptionPlans);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [planIdToDelete, setPlanIdToDelete] = useState(null);
  const [newPlan, setNewPlan] = useState({
    name: "",
    duration: "",
    interval: "Day",
    interval_value: 1,
    features: "",
    price: 0,
  });

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        await dispatch(fetchSubscriptionPlans());
      } catch (err) {
        toast.error("Error fetching subscription plans");
      }
    };

    fetchPlans();
  }, [dispatch]);

  const handleDeleteClick = (id) => {
    setPlanIdToDelete(id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deleteSubscriptionPlan(planIdToDelete));
      toast.success("Subscription plan deleted successfully");
      setShowDeleteModal(false);
    } catch (err) {
      toast.error("Error deleting subscription plan");
    }
  };

  const handleCloseDelete = () => setShowDeleteModal(false);

  const handleAddOrEditPlanClick = (plan = null) => {
    if (plan) {
      setNewPlan(plan);
      setIsEditMode(true);
    } else {
      setNewPlan({
        name: "",
        duration: "",
        interval: "Day",
        interval_value: 1,
        features: "",
        price: 0,
      });
      setIsEditMode(false);
    }
    setShowModal(true);
  };

  const handleSavePlan = async () => {
    try {
      if (isEditMode) {
        await dispatch(updateSubscriptionPlan({ id: newPlan.id, plan: newPlan }));
        toast.success("Subscription plan updated successfully");
      } else {
        await dispatch(addSubscriptionPlan(newPlan));
        toast.success("Subscription plan added successfully");
      }
      await dispatch(fetchSubscriptionPlans());
      setShowModal(false);
    } catch (err) {
      toast.error("Error saving subscription plan");
    }
  };
  

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Subscription Plans</h2>
        <Button variant="primary" onClick={() => handleAddOrEditPlanClick()}>
          Add New Plan
        </Button>
      </div>
      {loading && <p>Loading...</p>}
      {/* {error && <p>Error: {error}</p>} */}
      {!loading  && plans.length === 0 && (
        <p>No subscription plans available.</p>
      )}
      {!loading && plans.length > 0 && (
        <Table striped hover responsive>
          <thead>
            <tr className="text-center">
              <th>ID</th>
              <th>Name</th>
              <th>Duration</th>
              <th>Interval</th>
              <th>Par Interval</th>
              <th>Price</th>
              <th>Features</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan, index) => (
              <tr key={plan.id} className="text-center">
                <td>{index + 1}</td>
                <td>{plan.name}</td>
                <td>{plan.duration}</td>
                <td>{plan.interval}</td>
                <td>{plan.interval_value}</td>
                <td>{plan.price} Dh</td>
                <td>{plan.features || "null"}</td>
                <td>
                  <FaTrash
                    className="m-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleDeleteClick(plan.id)}
                  />
                  <FaEdit
                    className="m-2"
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={() => handleAddOrEditPlanClick(plan)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Confirmation Modal for Deletion */}
      <Modal show={showDeleteModal} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this subscription plan?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Adding/Editing Plan */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? "Edit Subscription Plan" : "Add New Subscription Plan"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="formPlanName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter plan name"
                    value={newPlan.name}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, name: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formPlanDuration">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter duration"
                    value={newPlan.duration}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, duration: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formPlanInterval">
                  <Form.Label>Interval</Form.Label>
                  <Form.Control
                    as="select"
                    value={newPlan.interval}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, interval: e.target.value })
                    }
                  >
                    <option value="Day">Day</option>
                    <option value="Month">Month</option>
                    <option value="Year">Year</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formPlanIntervalValue">
                  <Form.Label>Interval Value</Form.Label>
                  <Form.Control
                    type="number"
                    value={newPlan.interval_value}
                    onChange={(e) =>
                      setNewPlan({
                        ...newPlan,
                        interval_value: parseInt(e.target.value, 10),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="formPlanFeatures">
                  <Form.Label>Features</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={newPlan.features}
                    onChange={(e) =>
                      setNewPlan({ ...newPlan, features: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formPlanPrice">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    value={newPlan.price}
                    onChange={(e) =>
                      setNewPlan({
                        ...newPlan,
                        price: parseFloat(e.target.value),
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSavePlan}>
            {isEditMode ? "Save Changes" : "Add Plan"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
