import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import logo from "../assets/Logo.png";
import { logout } from "../redux/Auth/authSlice";
import './dashboard.css';


const Dashboard = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSidebarClosed, setSidebarClosed] = React.useState(true);
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          setSidebarClosed(true);
        } else {
          setSidebarClosed(false);
        }
  
      };
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    const handleSidebarToggle = () => {
      setSidebarClosed((prev) => !prev);
    };
  
  
  
    const isActiveRoute = (route) => {
      return location.pathname === route;
    };
  
  
    const handleLogout = async (e) => {
      e.preventDefault();
      dispatch(logout());
      navigate("/");
    };
  
  
    return (
      <>
        <div className={`sidebar ${isSidebarClosed ? "close" : ""}`}>
        <Link to="#" className={`${!isSidebarClosed ?"logo" : "logo-sm" }`}>
            <img src={logo} alt="" width="100px"/>
          </Link>
          <ul className="side-menu">
            <li
              className={
                isActiveRoute("/dashboard/subscription") ? "actives" : ""
              }
            >
              <Link to="/dashboard/subscription" className="link">
                <i className="bx bxs-building-house"></i>
                Centers
              </Link>
            </li>
            <li
              className={isActiveRoute("/dashboard/Plans") ? "actives" : ""}
            >
              <Link to="/dashboard/Plans" className="link">
                <i className="bx bx-credit-card"></i>
                Plans
              </Link>
            </li>
          </ul>
          <ul className="side-menu">
            <li>
            <Link
                to="#"
                className="logout link"
                onClick={(e) => handleLogout(e)}
              >
                <i className="bx bx-log-out-circle"></i>
                Logout
              </Link>
            </li>
          </ul>
        </div>
        <div className="content">
          <nav>
            <i className="bx bx-menu" onClick={handleSidebarToggle}></i>
            <form>{/*  */}</form>
          </nav>
          <main>
            <Outlet />
          </main>
        </div>
      </>
    );
};

export default Dashboard;
