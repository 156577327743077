import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import './PersistLogin.css';

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const {accessToken} = useSelector(e=>e.auth)
    
    useEffect(() => {
        const verifyRefreshToken = async()=>{
            try {
                await refresh()
            } catch (err) {
            }finally{
                setIsLoading(false)
            }
        }
        !accessToken ?  verifyRefreshToken() : setIsLoading(false)
    }, [accessToken]);
    return accessToken ? (
        <Outlet />
    ) : (isLoading ? 
            (
            <div className="loading-container">
                <ClipLoader color="blue" loading={isLoading} size={100} />
            </div>
            ) 
            :
            (
            <Outlet />
            )
        );
}

export default PersistLogin