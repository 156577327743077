import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import InterceptorsProvider from "./provider/InterceptorsProvider";
import { Provider } from 'react-redux';
import store from './redux/store';
import 'boxicons/css/boxicons.min.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
        <InterceptorsProvider>
          <BrowserRouter>
              <App />
          </BrowserRouter>
          </InterceptorsProvider>
          </Provider>
);
