import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const RequiredAdminRules = ({allowedRules}) => {
    const {user} = useSelector(e=>e.auth)
    return (
        allowedRules.includes(user?.role)
            ? <Outlet/>
            : user
                ? <Navigate to="/dashboard/subscription"/>
                : <Navigate to="/"/>
    ) 
}

export default RequiredAdminRules